import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
//import { prototype } from 'vue/types/umd'

Vue.config.productionTip = false;

Vue.prototype.$convertToComa = (num: string | number,isconv: boolean) => {
  // console.log([num,isconv])
  
  if(isconv){
      return Number(num).toLocaleString();
  }
  else{
      return num;
  }
}
Vue.prototype.$RoundNums = (num: number, t: number) => {
  
  if(isNaN(t)){
    t = 2;
  }
  let mul = 10;
  for(let i = 1;i<=t;i++){
    if(t != 1 && i == 1) continue;
    mul = mul * 10;
  }
  // num = mul*num;
  // num = parseFloat(num.toFixed(t));
  
  num = Math.round(num * mul) / mul;
  const nums = num.toString()
  if((Math.abs(parseFloat(nums)) - Math.abs(parseInt(nums)) < 0.10) || (Math.abs(parseFloat(nums)) - Math.abs(parseInt(nums)) > 0.90)){
    return parseInt(nums);
  }
  return num;
}
Vue.prototype.$RoundNum = (num: number) => {
 
  num = num * 100;
  num = Math.round(num);
  return num/100;
}
Vue.prototype.$Console = (title: string,log:any,sh:number) => {
  if(sh != 1 ) return false
  console.log(title,log);
}
Vue.prototype.$GetTimeStamp = (stringdate: string ) => {
  const myDate = stringdate.substring(0,10).split("-");
  const newDate = new Date( parseInt(myDate[0]), parseInt(myDate[1]) - 1, parseInt(myDate[2]));
  return (newDate.getTime()/1000);
}
Vue.prototype.$EndService = (startdate: number , enddate: number, salary: number) => {
  if(startdate > enddate){
    return 0;
  }
  let def = enddate - startdate;
  def = def / 3600;
  def = def / 24;
  const y = Math.trunc(def/360);
  const m = Math.trunc((def-(y*360))/30);
  const d = def - y*360 - m*30;
  if(y < 0){
    return 0;
  }
  let total = 0;
  total = y * salary + m * (salary / 12) + d * (salary / 360)
  return total;
}
Vue.prototype.$calcTax = (num: number, t: number) => {
  let ret = 0;
  ret = Math.round((num / t) * 100000);
  ret = +ret / 1000
  return ret;
}
Vue.prototype.$calcVat = (num: number, t: number) => {
  const ret = {
    tot: 0,
    ftot: 0,
    vat: 0
  }
  if(t == 1){
    ret.tot = num;
    ret.ftot = num;
    ret.vat = 0;
  }
  else if(t == 2){
    ret.tot = num;
    ret.ftot = 0;
    ret.vat = +ret.tot * 0.15;
    ret.ftot = +ret.tot + +ret.vat;
  }
  else if(t == 3){
    ret.ftot = num;
    ret.tot = Math.round((+ret.ftot / 115) * 100000);
    ret.tot = +ret.tot / 1000
    ret.vat = +ret.ftot - +ret.tot;
    ret.vat = Math.round((+ret.vat * 100)) / 100;
  }
  return ret;
}
Vue.prototype.$COOKIEPhase = 'SAMCCCC-Cookies';
Vue.prototype.$SAMCOTEC = {
  ip_url: 'https://ipinfo.io/json',
  COOCKIE: 'SAMCCCC-Cookies',
  connected: false,
  ip: null,
  full_name: null,
  r_path: '/api/index.php',
  DOMIAN: '192.168.1.18',
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
